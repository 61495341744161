import "./App.scss";
import Auth from "./Auth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getEnvOrThrow } from "./getEnvOrThrow";

const GOOGLE_CLIENT_ID = getEnvOrThrow("REACT_APP_GOOGLE_AUTH_CLIENT_ID");

function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="App">
        <Auth />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
