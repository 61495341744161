const hostPattern = /^(?<transport>(http|https):\/\/)?(?<hostname>[a-zA-Z0-9_.+-]+)(?<port>:\d+)?(?<path_query>\/.*)?$/;

function extractHostname(host: string) {
    const match = hostPattern.exec(host);
    if (!match || !match.groups || !match.groups.hostname)
        return undefined;
    return match.groups.hostname;
}

/** Evaluates the current hostname against a list of options and returns the closest url match.
 * Example app.mddx.us will map to auth.mddx.us (because they both share the root `mddx.us`)
 * @returns string - the hostname of the auth provider
 */
export function getAuthHost(): { success: true, authHost: string } | { success: false, error: string } {
    if (!process.env.REACT_APP_AUTH_HOSTS)
        return { success: false, error: 'No auth hosts available.' };
    const self = window.location.hostname.toLowerCase().split('.');
    const parsedOptions = process.env.REACT_APP_AUTH_HOSTS.toLowerCase().split('|')
        .map(authHost => ({
            host: authHost,
            hostname: extractHostname(authHost),
        }));
    if (parsedOptions.find(o => !o.hostname))
        return { success: false, error: 'Invalid auth host.' };
    const authHosts = parsedOptions
        .map(o => {
            const hostname = o.hostname!.split('.');
            for (let h = hostname.length - 1, s = self.length - 1; h >= 0 && s >= 0; h--, s--)
                if (hostname[h] !== self[s])
                    return { host: o.host, match: hostname.length - (h + 1) };
            return { host: o.host, match: hostname.length };
        })
        .sort((a, b) => b.match - a.match);
    return authHosts.length > 0
        ? { success: true, authHost: authHosts[0].host }
        : { success: false, error: 'No matching auth host found.' };
}