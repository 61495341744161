import { useGoogleLogin } from "@react-oauth/google";
import { DebugView } from "./DebugView";
import { authenticate, deauthenticate, validate } from "./authenticate";
import { AuthStatus, useAuthState } from "./AuthState";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "./images/Color.svg";
import "./Auth.scss";

const CLEAR_QUERY_STRING = false;

const REDIRECT_PARAM = "redirect_uri";
const INCLUDE_TOKEN_PARAM = "include_token";
const RETURNED_TOKEN_PARAM = "token_param";

function Auth() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = useAuthState();

  // this effect moves config from the URL into state
  useEffect(
    () => {
      const query = location.search
        ? new URLSearchParams(location.search)
        : null;
      let queryModified = false;

      let redirect: boolean = false;
      let redirect_uri: string | undefined = undefined;
      let include_token: boolean = false;
      let token_param: string | undefined = undefined;

      const q_redirect =
        query && query.has(REDIRECT_PARAM) ? query.get(REDIRECT_PARAM) : null;
      if (q_redirect !== null && q_redirect.trim().length > 0) {
        redirect = true;
        redirect_uri = q_redirect;

        state.setRedirectAllowed("validating");
        validate(redirect_uri).then((r) => {
          state.setRedirectAllowed(r.isAllowed);
        });

        if (CLEAR_QUERY_STRING) {
          query!.delete(REDIRECT_PARAM);
          queryModified = true;
        }
      }

      const q_include_token =
        query && query.has(INCLUDE_TOKEN_PARAM)
          ? /^(yes|true|1)$/i.test(query.get(INCLUDE_TOKEN_PARAM)!)
          : null;
      if (q_include_token !== null) {
        include_token = q_include_token;
        if (CLEAR_QUERY_STRING) {
          query!.delete(INCLUDE_TOKEN_PARAM);
          queryModified = true;
        }
      }

      const q_token_param =
        query && query.has(RETURNED_TOKEN_PARAM)
          ? query.get(RETURNED_TOKEN_PARAM)
          : null;
      if (q_token_param !== null) {
        token_param = q_token_param;
        if (CLEAR_QUERY_STRING) {
          query!.delete(RETURNED_TOKEN_PARAM);
          queryModified = true;
        }
      }

      state.setRedirect({
        redirect,
        redirect_uri,
        include_token,
        token_param,
      });

      if (queryModified) {
        console.log(query?.toString());
        navigate({ search: query!.toString() }, { replace: true });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      authenticate(tokenResponse.access_token).then((r) => {
        if (r.success) {
          state.setAuthenticated(r.data.accessToken, r.data.userInfo);
        } else {
          state.setNotAuthenticated(r.error);
        }
      });
    },
    onError(errorResponse) {
      state.setNotAuthenticated(errorResponse.error);
    },
  });

  // initializes login state
  useEffect(
    () => {
      if (state.status !== AuthStatus.Uninitialized) return;
      state.setLoading();
      authenticate().then((r) => {
        if (r.success) {
          state.setAuthenticated(r.data.accessToken, r.data.userInfo);
        } else {
          state.setNotAuthenticated();
        }
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.status]
  );

  useEffect(
    () => {
      if (
        state.status === AuthStatus.Authenticated &&
        state.redirect &&
        state.redirect_uri_allowed === true
      ) {
        const query = new URL(state.redirect_uri!);
        if (state.include_token) {
          query.searchParams.append(state.token_param, state.accessToken!);
        }
        window.location.replace(query.toString());
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.status, state.redirect, state.redirect_uri_allowed]
  );

  return (
    <div>
      <div className="centered">
        <img
          className="main-logo"
          src={logo}
          alt="Maddox Industrial Transformer"
        />
        {(() => {
          switch (state.status) {
            case AuthStatus.Uninitialized:
            case AuthStatus.Loading:
              return <div>Loading...</div>;
            case AuthStatus.NotAuthenticated:
            case AuthStatus.Authenticating:
              return (
                <button
                  className="g-button"
                  disabled={state.status === AuthStatus.Authenticating}
                  onClick={(e) => {
                    state.setAuthenticating();
                    login();
                  }}
                >
                  <span className="icon" />
                  <span className="buttonText">Sign in with Google</span>
                </button>
              );
            case AuthStatus.Authenticated:
            case AuthStatus.Deauthenticating:
              return (
                <button
                  className="g-button"
                  disabled={state.status === AuthStatus.Deauthenticating}
                  onClick={(e) => {
                    state.setDeauthenticating();
                    deauthenticate().then((d) => state.setNotAuthenticated());
                  }}
                >
                  <span className="buttonText">Sign Out</span>
                </button>
              );
            case AuthStatus.Error:
              return <div className="error">{state.error}</div>;
          }
        })()}
      </div>
      <DebugView>
        <pre>status: {state.status}</pre>
        <pre>redirect_uri: {state.redirect_uri}</pre>
        <pre>redirect_uri_allowed: {state.redirect_uri_allowed}</pre>
        <pre>include_token: {state.include_token}</pre>
        <pre>token_param: {state.token_param}</pre>
        <pre>error: {state.error}</pre>
      </DebugView>
    </div>
  );
}

export default Auth;
