import { useState } from "react";
import './DebugView.scss';

export function DebugView({ children }: { children: any }) {
    const [expanded, setExpanded] = useState<boolean>(false);

    const onExpandCollapse = () => {
        setExpanded(!expanded);
    }

    const view = (() => {
        if (!expanded) return null;
        return <div>
            {children}
        </div>
    })();

    return <div id="debug-view">
        <button className="debug-corner-button" onClick={onExpandCollapse}>{expanded ? '-' : '+'}</button>
        {view}
    </div>
}