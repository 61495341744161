import { getAuthHost } from "./authHost";

type AuthenticateResponse =
  | {
      success: false;
      error: string;
    }
  | {
      success: true;
      data: { accessToken: string; userInfo: UserInfo };
    };

export interface ValidatedRedirect {
  redirect: string;
  origin: string | null;
  isAllowed: boolean;
}

export interface UserInfo {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePhotoUri: string;
}

export async function validate(redirect: string): Promise<ValidatedRedirect> {
  const auth = getAuthHost();
  if (!auth.success) return { redirect, origin: null, isAllowed: false };
  const { authHost } = auth;
  const redirectUrl = new URL(redirect);
  const res: any = await fetch(
    `${authHost}/api/origin/${redirectUrl.hostname}`,
    {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }
  ).then((res) => res.json());

  if (res.success)
    return { redirect, origin: res.data.origin, isAllowed: res.data.isAllowed };
  else return { redirect, origin: null, isAllowed: false };
}

/** Calls the appropriat Auth server with the provided Google token
 * (Sets the refreshToken in an http-only cookie and also returns a JWT)
 */
export async function authenticate(
  googleToken?: string
): Promise<AuthenticateResponse> {
  const auth = getAuthHost();
  if (!auth.success) return auth;
  const { authHost } = auth;
  return fetch(`${authHost}/api/authenticate`, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(
      googleToken
        ? { google_token: googleToken }
        : { checking_credentials: true }
    ),
  })
    .then((res) => res.json())
    .then((res: AuthenticateResponse) => {
      if (res.success) return { success: true, data: res.data };
      else return { success: false, error: res.error };
    });
}

/** Calls the appropriate Auth server with the provided Google token
 * (Revokes and clears the refreshToken in an http-only cookie)
 */
export async function deauthenticate(): Promise<
  { success: true } | { success: false; error: string }
> {
  const auth = getAuthHost();
  if (!auth.success) return auth;
  const { authHost } = auth;

  return fetch(`${authHost}/api/signout`, {
    method: "POST",
    credentials: "include",
  }).then((res) => res.json());
}
