import { UserInfo } from "./authenticate";
import { create } from "zustand";

const DEFAULT_RETURNED_TOKEN_PARAM = "access_token";

export enum AuthStatus {
  Uninitialized = "uninitialized", // not initialized
  Loading = "loading", // initial request (to see if there is an active refreshToken) is in progress
  NotAuthenticated = "not_authenticated", // user is not authenticated (an active refreshToken is not present)
  Authenticated = "authenticated", // user is authenticated (an active refreshToken is present)
  Authenticating = "authenticating", // user authentication is in progress
  Deauthenticating = "deauthenticating", // user is signing out (attempting to revoke their refreshToken)
  Error = "error", // some error state
}

export interface AuthState {
  status: AuthStatus;
  accessToken: string | undefined;
  userInfo: UserInfo | undefined;
  redirect: boolean;
  redirect_uri: string | undefined;
  redirect_uri_allowed: "validating" | true | false | undefined;
  include_token: boolean | undefined;
  token_param: string;
  error: string | undefined;

  setLoading: () => void;
  setAuthenticated: (accessToken: string, userInfo: UserInfo) => void;
  setNotAuthenticated: (error?: string) => void;
  setAuthenticating: () => void;
  setDeauthenticating: () => void;
  setRedirectAllowed: (value: "validating" | true | false) => void;
  setRedirect: (props: {
    redirect: boolean;
    redirect_uri: string | undefined;
    include_token: boolean;
    token_param: string | undefined;
  }) => void;
}

export const useAuthState = create<AuthState>()((set) => ({
  status: AuthStatus.Uninitialized,
  accessToken: undefined,
  userInfo: undefined,
  redirect: false,
  redirect_uri: undefined,
  redirect_uri_allowed: undefined,
  include_token: undefined,
  token_param: DEFAULT_RETURNED_TOKEN_PARAM,
  error: undefined,

  setLoading: () =>
    set((state) => ({
      ...state,
      status: AuthStatus.Loading,
    })),
  setAuthenticated: (accessToken: string, userInfo: UserInfo) =>
    set((state) => ({
      ...state,
      status: AuthStatus.Authenticated,
      accessToken,
      userInfo,
      error: undefined,
    })),
  setNotAuthenticated: (error?: string) =>
    set((state) => ({
      ...state,
      status: AuthStatus.NotAuthenticated,
      accessToken: undefined,
      userInfo: undefined,
      error,
    })),
  setAuthenticating: () =>
    set((state) => ({
      ...state,
      status: AuthStatus.Authenticating,
      error: undefined,
    })),
  setDeauthenticating: () =>
    set((state) => ({
      ...state,
      status: AuthStatus.Deauthenticating,
      error: undefined,
    })),
  setRedirectAllowed: (value: "validating" | true | false) =>
    set((state) => ({
      ...state,
      redirect_uri_allowed: value,
    })),
  setRedirect: (props: {
    redirect: boolean;
    redirect_uri: string | undefined;
    include_token: boolean;
    token_param?: string;
  }) =>
    set((state) => ({
      ...state,
      redirect: props.redirect,
      redirect_uri: props.redirect_uri,
      include_token: props.include_token,
      token_param: props.token_param ?? state.token_param,
    })),
}));
